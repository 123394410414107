import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { Link } from 'react-router-dom'

export default function MenuClient() {
	const { t } = useTranslation()	
	const {client} = useAuth()
	
  	return (
		<div className='pie fixed-bottom mx-auto'>
			<Navbar id="menu-client" className='mx-auto'>
				<Nav className="mx-auto">
					<Nav.Link as={Link} to="/client/restaurants" className='d-flex flex-column justify-content-end align-items-center'>
						<img src="/images/restaurants.svg" alt="Restaurantes" className='mb-2'/>
						{t('client.menu.restaurants')}
					</Nav.Link>
					<Nav.Link as={Link} to="/client/favourites" className='d-flex flex-column justify-content-end align-items-center'>
						<img src="/images/favourites.svg" alt="Favoritos" className='mb-2'/>
						{t('client.menu.favourites')}
					</Nav.Link>
					<Nav.Link as={Link} to="/client/ranking" className='d-flex flex-column justify-content-end align-items-center'>
						<img src="/images/ranking.svg" alt="Ranking" className='mb-2'/>
						{t('client.menu.ranking')}
					</Nav.Link>
					<Nav.Link href="https://redcordobasingluten.es/mapa-establecimientos" target="_blank" rel="noopener noreferrer" className='d-flex flex-column justify-content-end align-items-center'>
						<img src="/images/localization.svg" alt="Localización" className='mb-2'/>
						{t('client.menu.localization')}
					</Nav.Link>
					<Nav.Link href="https://redcordobasingluten.es" target="_blank" rel="noopener noreferrer" className='d-flex flex-column justify-content-end align-items-center'>
						<img src="/images/logotipo-inicio-app.png" alt="Localización" className='mb-2'/>
						{t('client.menu.web')}
					</Nav.Link>
					{client
						?
							<>
								<Nav.Link as={Link} to="/client/config" className='d-flex flex-column justify-content-end align-items-center'>
									<img src="/images/profile.svg" alt="Perfil" className='mb-2'/>
									{t('client.menu.profile')}
								</Nav.Link>
							</>
						:
							<>
								<Nav.Link as={Link} to="/login" className='d-flex flex-column justify-content-end align-items-center'>
									<img src="/images/profile.svg" alt="Perfil" className='mb-2'/>
									{t('client.menu.profile')}
								</Nav.Link>
							</>
					}
				</Nav>
			</Navbar>			
		</div>
	)
}