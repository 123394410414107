import React from 'react'
import { BrowserRouter, Routes, Route } from "react-router-dom"
import AuthRouter from './routers/AuthRouter'
import AuthProvider from './context/auth/AuthProvider'
import PrivateAdminRouter from './routers/PrivateAdminRouter'
import AdminRouter from './routers/AdminRouter'
import ClientRouter from './routers/ClientRouter'

export default function App() {
	return (
		<>
			<BrowserRouter>
				<AuthProvider>			
				<Routes>
					<Route path="/admin/*" element={ <PrivateAdminRouter><AdminRouter /></PrivateAdminRouter>} />
					<Route path="/client/*" element={ <ClientRouter />} />
					<Route path="*" element={<AuthRouter />}/>
				</Routes>
				</AuthProvider>
			</BrowserRouter>
		</>
	)
}
