import React from 'react'
import { Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

const FilterTypeRestaurant = ({categories, onFilterChange}) => {
    const { t } = useTranslation()

    const handleFilterSelect = ({target}) => {
        onFilterChange(target.value)
    }

    return (
        <>
            <h4>{t('admin.restaurants.category')}</h4>
            <Form.Select className="form-control mt-2" onChange={handleFilterSelect} id="category" name="category">
                <option value="">{t('typerestaurant.All')}</option>
                {categories?.map(category => (
                    <option key={category} value={category}>
                        {t(`typerestaurant.${category}`)}
                    </option>
                ))}
            </Form.Select>
        </>
    )
}

export default FilterTypeRestaurant