import { useTranslation } from "react-i18next"
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const ResetPasswordSuccess = () => {
    const { t } = useTranslation()

    return (
        <>
            <div className='cabecera-auth d-flex justify-content-center align-items-center'>
                <img src="/images/logotipo-menu-login.png" alt="logotipo" className='logo'></img>
                <Link className="back px-3" to="/login">
                    <i className='fa fa-chevron-left fa-2x'></i>
                </Link>
            </div>        
            <Container className='mt-5 px-5'>
                <Row>
                    <Col className='text-center'>
                        <h1>{t('forgot.restoresuccess')}</h1>
                    </Col>
                </Row>
                <Row className='my-4'>
                    <Col className='text-center'>
                        <Link to="/login" className='btn btn-primary py-2'>
                            {t('forgot.continue')}
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    )    
}