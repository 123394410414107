export const restaurantsReducer = (state = {loading: true, resultrestaurant: null, restaurants: null, restaurant: null, newopinions: 0, opinions: null } , action) => {
    switch (action.type) {
        case 'RESTAURANT_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                restaurants: action.payload.restaurants,
                opinions: action.payload.opinions
            }
        case 'RESTAURANT_GETNEWOPINIONS_SUCCESS':
            return {
                ...state,
                loading: false,
                newopinions: action.payload
            }
        case 'RESTAURANT_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                restaurant: action.payload
            }
        case 'RESTAURANT_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                restaurants: [...state.restaurants, action.payload.restaurant],
                resultrestaurant: action.payload.message
            }
        case 'RESTAURANT_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resultrestaurant: action.payload
            }
        case 'RESTAURANT_SETOPINION_SUCCESS':
            return {
                ...state,
                loading:false,
                restaurant: {...state.restaurant, opinions: action.payload.newopinions, averagerating: action.payload.newaveragerating}
            }
        case 'RESTAURANT_ENABLEDISABLE_SUCCESS':
            return {
                ...state,
                loading:false,
                restaurant: {...state.restaurant, active: action.payload.staterestaurant},
                resultrestaurant: action.payload.message
            }
        case 'RESTAURANT_RESET_RESULT':
            return {...state,
                resultrestaurant: null
            }
        case 'RESTAURANT_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'RESTAURANT_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        default:
            return state
    }
}

