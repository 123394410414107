import React from 'react'
import ReactDOM from 'react-dom'
import './translations/i18n'
import { Provider } from 'react-redux'
import store from './store'
import App from './App'
import moment from 'moment-timezone'

moment.tz.setDefault('Europe/Madrid')

require('dotenv').config()

window.jwt = ''

ReactDOM.render(
	<Provider store={store}>
		<React.StrictMode>
			<App/>
		</React.StrictMode>
	</Provider>,
	document.getElementById('root')
)