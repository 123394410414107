import React, { useEffect } from 'react'
import {Navbar, Nav, Container} from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { getNewopinions } from '../../actions/restaurantsActions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

export default function MenuAdmin() {
	const { t } = useTranslation()
	const {logout} = useAuth()
	const dispatch = useDispatch()

    const {newopinions} = useSelector(state=>state.restaurants)

    useEffect(() => {
		dispatch(getNewopinions())
	}, [dispatch])

  	return (
		<div className='cabecera d-flex justify-content-center align-items-center px-4'>
			<Navbar id="menu-admin" className='my-3 me-auto'>
				<Container>
					<Navbar.Brand href="#home">
						<img
							src="/images/logotipo-menu-reducido.png"
							className="d-inline-block align-top justify-center"
							alt="Logotipo Córdoba Sin Gluten"
							id="logo-menu" className='logo'
						/>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav" >
						<Nav className="me-auto pt-5 ps-3">
							<Nav.Link as={Link} to="/admin/dashboard">{t('admin.dashboard.index')}</Nav.Link>
							<Nav.Link as={Link} to="/admin/users">{t('admin.dashboard.users')}</Nav.Link>
							<Nav.Link as={Link} to="/admin/restaurants">{t('admin.dashboard.restaurants')}</Nav.Link>
							<Nav.Link as={Link} to="/admin/opinions">
								{t('admin.dashboard.opinions')}
								{newopinions !== 0
									?
										<>
											<span className='ms-1'>({newopinions})</span>
										</>
									:
										''
								}
							</Nav.Link>
							<Nav.Link as={Link} to="/admin/logs">{t('admin.dashboard.logs')}</Nav.Link>
							<Nav.Link as={Link} to="/" onClick={logout}>{t('signin.logout')}</Nav.Link>
						</Nav>
					</Navbar.Collapse>
				</Container>
			</Navbar>
        </div>
		
	)
}