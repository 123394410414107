import React, { Fragment, useEffect, useState } from 'react'
import { Container, Button, Col, Row, ProgressBar, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getRestaurant, setOpinion } from '../../actions/restaurantsActions'
import { changeFavourites, getUser } from '../../actions/usersActions'
import Rating from 'react-rating'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Swal from 'sweetalert2'
import Loader from '../../components/loaders/Loader'

export default function Restaurant() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {client} = useAuth()

    const { id } = useParams()

    const {restaurant, loading: restaurantLoading} = useSelector(state=>state.restaurants)
    const {user, loading: userLoading} = useSelector(state=>state.users)

    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client){
            dispatch(getUser(client?.id))
            dispatch(getRestaurant(id))
        }else{
            dispatch(getRestaurant(id))
        }
    },[dispatch, t, id, client])

    const setFavourite = (restaurantid, newstate) => {
        dispatch(changeFavourites(restaurantid, newstate, user.id))
    }

    const handleRatingChange = (value) => {
      setRating(value)
    }

    const handleCommentChange = (event) => {
        setComment(event.target.value)
    }

    const sendOpinion = () => {
        dispatch(setOpinion(user.id, rating, comment, id))
        Swal.fire({
            showConfirmButton: true,
            showDenyButton: false,
            confirmButtonText: t('global.great'),
            icon: 'success',
            text: t('client.restaurants.opinionsforverify')
        })
    }

    return (
        <>
            {
                restaurantLoading
                ?
                    <Loader/>
                :
                restaurant
                ?
                    <>
                        {restaurant?.images?.length > 0
                            ?
                                <Carousel showArrows={true} infiniteLoop={true} autoPlay={true}>
                                    {restaurant?.images?.map((image, index) => (
                                        <div key={index}>
                                            <img src={image} alt={`Imagen ${index}`} />
                                        </div>
                                    ))}
                                </Carousel>
                            :
                                ''
                        }
                        <Container>
                            <Row className="my-3">
                                <Col className='d-flex'>
                                    <div className="logotipo">
                                        <img src={restaurant.image} alt="logotipo" style={{width: '180px'}}></img>
                                    </div>
                                    <div className="datos">
                                        <h4>{restaurant.name}</h4>
                                        <div className='gray mb-3'>{t('typerestaurant.'+restaurant.category)}</div>
                                        {restaurant?.averagerating >0
                                        ?
                                            <div className='d-flex align-items-center'>
                                                <ProgressBar now={((restaurant?.averagerating/5)*100)} title={restaurant?.averagerating} className='me-2'/>
                                                {restaurant?.averagerating} / 5
                                            </div>
                                        :
                                            ''
                                        }
                                    </div>
                                    <div className="botones">
                                        {user
                                            ?
                                                user?.favourites?.includes(restaurant._id)
                                                    ?
                                                        <div className='favorito-selected' onClick={() => setFavourite(restaurant._id, false)}>
                                                            {
                                                                userLoading
                                                                ?
                                                                    <span className="spinner-border spinner-border-sm align-middle"></span>
                                                                :
                                                                    <i className='fa fa-heart'></i>
                                                            }
                                                        </div>
                                                    :
                                                        <div className='favorito' onClick={() => setFavourite(restaurant._id, true)}>
                                                            {
                                                                userLoading
                                                                ?
                                                                    <span className="spinner-border spinner-border-sm align-middle"></span>
                                                                :
                                                                    <i className='fa fa-heart-o'></i>
                                                            }
                                                        </div>
                                            :
                                                ''
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {localStorage.getItem('csg-lang')==='es' || localStorage.getItem('csg-lang') === null
                            ?
                                restaurant?.description
                                ?
                                    <Row className="my-3">
                                        <Col className='gray'>
                                            {restaurant.description}
                                        </Col>
                                    </Row>
                                :
                                    ''
                            :
                                restaurant?.descriptionen
                                ?
                                    <Row className="my-3">
                                        <Col className='gray'>
                                            {restaurant.descriptionen}
                                        </Col>
                                    </Row>
                                :
                                    ''
                            }
                            {restaurant?.phone
                                ?
                                    <Row className="my-5">
                                        <Col className='px-5'>
                                            <a href={`tel:${restaurant.phone}`} className="btn btn-primary">
                                                <i className='fa-solid fa-phone'></i> {t('client.restaurants.call')}
                                            </a>
                                        </Col>
                                    </Row>
                                :
                                    ''
                            }
                            <Row className="my-3">
                                <Col>
                                {restaurant?.positionx !== '' && restaurant.positionx !== '0'
                                ?
                                    <div dangerouslySetInnerHTML={{__html: '<iframe width="100%" height="400" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=es&amp;q=' + restaurant.positionx + ',%20' + restaurant.positiony + '+(location)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>'}}></div>
                                :
                                    ''
                        }
                                </Col>
                            </Row>
                            {restaurant?.address !== '' 
                                ?
                                    <Row className="my-3">
                                        <Col className='px-5 datos-direcciones'>
                                            <i className='fa-solid fa-location-dot'></i> {restaurant.address}
                                        </Col>
                                    </Row>
                                :
                                    ''
                            }
                            {restaurant?.phone !== '' 
                                ?
                                    <Row className="my-3">
                                        <Col className='px-5 datos-direcciones'>
                                            <i className='fa-solid fa-phone'></i> {restaurant.phone}
                                        </Col>
                                    </Row>
                                :
                                    ''
                            }
                            {restaurant?.web !== '' 
                                ?
                                    <Row className="my-3">
                                        <Col className='px-5 datos-direcciones'>
                                            <a href={restaurant.web} target='_blank' rel="noreferrer">
                                                <i className="fa-solid fa-globe"></i> {t('client.restaurants.web')}:<br/>{restaurant.web}
                                            </a>
                                        </Col>
                                    </Row>
                                :
                                    ''
                            }
                            {restaurant?.facebook !== '' || restaurant?.x !== '' || restaurant?.instagram !== ''
                                ?
                                    <Row className="my-3">
                                        <Col className='px-5 datos-direcciones'>
                                            {t('client.restaurants.sociallinks')}: <br/>
                                            {restaurant?.facebook !== ''
                                                ?
                                                    <a href={restaurant?.facebook} target='_blank' rel='noreferrer'>
                                                        <i className="fa-brands fa-square-facebook fa-2x me-2"></i>
                                                    </a>
                                                :
                                                    ''
                                            }
                                            {restaurant?.x !== ''
                                                ?
                                                    <a href={restaurant?.x} target='_blank' rel='noreferrer'>
                                                        <i className="fa-brands fa-square-x-twitter fa-2x me-2"></i>
                                                    </a>
                                                :
                                                    ''
                                            }
                                            {restaurant?.instagram !== ''
                                                ?
                                                    <a href={restaurant?.instagram} target='_blank' rel='noreferrer'>
                                                        <i className="fa-brands fa-instagram fa-2x me-2"></i>
                                                    </a>
                                                :
                                                    ''
                                            }
                                        </Col>
                                    </Row>
                                :
                                    ''
                            }
                            <hr/>
                            <Row className="my-3">
                                <Col>
                                    {client
                                        ?
                                            <Fragment>
                                                {t('client.restaurants.writeopinion')} <br/>
                                                <Rating
                                                    initialRating={rating}
                                                    emptySymbol={<i className="far fa-star" style={{ color: 'gray' }} />}
                                                    fullSymbol={<i className="fas fa-star" style={{ color: 'gold' }} />}
                                                    onChange={handleRatingChange}
                                                /><br/>
                                                <Form.Control
                                                    className='mb-3'
                                                    as='textarea'
                                                    rows={5}
                                                    type='text'
                                                    name='comment'
                                                    value={comment}
                                                    onChange={handleCommentChange}
                                                    required
                                                />
                                                <Button onClick={() => sendOpinion()}>
                                                    {t('client.restaurants.sendopinion')}
                                                </Button><br/>
                                            </Fragment>
                                        :
                                            ''
                                    }
                                </Col>
                            </Row>
                        </Container>
                        <Container className=" p-4 mb-5">
                            <Row className="my-3">
                                <Col className='p-4 opiniones'>
                                    <h3>{t('client.restaurants.opinions')}</h3>
                                    {client
                                        ?
                                            ''
                                        :
                                            <Fragment>
                                                {t('client.restaurants.registerforopinion')} <br/>
                                            </Fragment>
                                    }
                                    <hr/>
                                    {restaurant?.opinions?.length > 0
                                        ?
                                            <div className='bloque-opiniones'>
                                            {restaurant?.opinions?.map((opinion, index) => (
                                                opinion?.active
                                                    ?
                                                        <Row key={index}>
                                                            <Col className='datos-opiniones'>
                                                                {opinion?.user?.username}<br/>
                                                                <div className='d-flex align-items-center'>
                                                                    <ProgressBar now={((opinion?.rating/5)*100)} title={opinion?.rating}/>
                                                                    <div className='ms-2'>{opinion?.rating}/5</div>
                                                                </div>
                                                                {opinion?.comment}
                                                            </Col>
                                                        </Row>
                                                    :
                                                        ''
                                            ))}
                                            </div>
                                        :
                                            ''
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </>
                :
                    ''
            }
        </>
    )
}