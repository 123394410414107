import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import NotFoundPage from '../pages/error/404'
import {Container} from 'react-bootstrap'
import MenuClient from '../components/menus/MenuClient'
import Restaurants from '../pages/client/Restaurants'
import Restaurant from '../pages/client/Restaurant'
import Favourites from '../pages/client/Favourites'
import Ranking from '../pages/client/Ranking'
import Config from '../pages/client/Config'
import HeaderClient from '../components/headers/HeaderClient'

export default function ClientRouter () {
	const navigate = useNavigate()

    useEffect(() => {
		let token = localStorage.getItem('jwt')

		if (token === 'undefined') {
			navigate('/login')
		}
    }, [navigate])

	return (
		<Container className="contenido">
			<HeaderClient/>
			<Routes>
				<Route path="/restaurants" element={<Restaurants/>}/>
				<Route path="/restaurant/:id" element={<Restaurant/>}/>
				<Route path="/favourites" element={<Favourites/>}/>
				<Route path="/ranking" element={<Ranking/>}/>
				<Route path="/config" element={<Config/>}/>
				<Route path="*" element={<NotFoundPage />}/>
			</Routes>
			<MenuClient/>
		</Container>
	)
}