import {useState} from 'react'
import Axios from 'axios'
import { useTranslation } from "react-i18next"
import Swal from 'sweetalert2'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import ChangeLanguage from '../../translations/ChangeLanguage'

/**
 * 
 * @returns 
 */
export const Forgot = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    
    /**
     * Comprueba los datos del formulario
     * @param {*} e evento
     */
    const handleSubmit = async (e) => {
        e.preventDefault()
        // Obtenemos el mail del usuario
        const clientEmail = e.target.email.value

        setIsLoading(true)
        
        // Enviamos la petición al /forgotpassword con el email
        await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/forgot/sendmail', {email: clientEmail})
            .then((res) => {
                setIsLoading(false)
                navigate('/emailsend/'+clientEmail)
            }).catch((err) => {
                setIsLoading(false)
                // Mostramos el popup informativo
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'error',
                    text: 'Ha habido un error al intentar enviar los datos, comprueba el correo introducido o vuelva a intentarlo más tarde'
                })
            })
    } 
    return (
        <>
            <div className='cabecera-auth d-flex justify-content-center align-items-center'>
                <img src="/images/logotipo-menu-login.png" alt="logotipo" className='logo'></img>
                <Link className="back px-3" to="/login">
                    <i className='fa fa-chevron-left fa-2x'></i>
                </Link>
                <ChangeLanguage />
            </div>        
            <Container className='mt-5 px-5'>
                <Row>
                    <Col className='text-center'>
                        <h1>{t('forgot.question')}</h1>
                        <h4>{t('forgot.help1')}</h4>
                        <h4>{t('forgot.help2')}</h4>
                    </Col>
                </Row>                      
                <Row>
                    <Col className="px-5">
                        <Form className="formulario" onSubmit={handleSubmit}>                        
                            <Row className="text-center my-3">
                                <Form.Control placeholder={t('forgot.email')} name="email" className="sd-input" type="email" autoComplete="off" />
                            </Row>
                            <Row className="text-center my-3">
                                <Col>
                                    <Button variant='primary' type="submit"  className="w-100 my-2">
                                        {isLoading
                                            ?
                                            <>
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </>
                                            :
                                            <>{t('forgot.submit')}</>
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )    
}
