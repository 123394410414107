import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFoundPage from '../pages/error/404'
import {Container} from 'react-bootstrap'
import MenuAdmin from '../components/menus/MenuAdmin'
import Dashboard from '../pages/admin/Dashboard'
import Users from '../pages/admin/users/Users'
import UsersEdit from '../pages/admin/users/UsersEdit'
import Restaurants from '../pages/admin/restaurants/Restaurants'
import RestaurantsEdit from '../pages/admin/restaurants/RestaurantsEdit'
import Logs from '../pages/admin/Logs'
import Opinions from '../pages/admin/restaurants/Opinions'
import OpinionsEdit from '../pages/admin/restaurants/OpinionsEdit'

export default function AdminRouter () {
	return (
		<Container className='contenido-admin'>
			<MenuAdmin/>
			<Routes>
				<Route path="/dashboard" element={<Dashboard/>}/>
				<Route path="/users" element={<Users/>}/>
				<Route path="/users/:id" element={<UsersEdit/>}/>
				<Route path="/restaurants" element={<Restaurants/>}/>
				<Route path="/restaurants/:id" element={<RestaurantsEdit/>}/>
				<Route path="/opinions" element={<Opinions/>}/>
				<Route path="/opinions/:id" element={<OpinionsEdit/>}/>
				<Route path="/logs" element={<Logs/>}/>
				<Route path="*" element={<NotFoundPage />}/>
			</Routes>
		</Container>
	)
}