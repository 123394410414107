export const usersReducer = (state = {loading: false, resultuser: null, users: null, user: null, useractivate: null, deletionResult: null } , action) => {
    switch (action.type) {
        case 'USER_GETALL_SUCCESS':
            return {
                ...state,
                loading: false,
                users: action.payload
            }
        case 'USER_GET_SUCCESS':
            return {
                ...state,
                loading: false,
                user: action.payload
            }
        case 'USER_ADD_SUCCESS':
            return {
                ...state,
                loading:false,
                resultuser: action.payload.message,
                useractivate: action.payload.idUser
            }
        case 'USER_EDIT_SUCCESS':
            return {
                ...state,
                loading:false,
                resultuser: action.payload
            }
        case 'USER_CHANGEFAVOURITES_SUCCESS':
            return {
                ...state,
                loading:false,
                user: {...state.user, favourites: action.payload}
            }
        case 'USER_ENABLEDISABLE_SUCCESS':
            return {
                ...state,
                loading:false,
                user: {...state.user, active: action.payload.stateuser},
                resultuser: action.payload.message
            }
        case 'USER_RESET_RESULT':
            return {...state,
                resultuser: null
            }
        case 'USER_LOADING':
            return {
                ...state,
                loading:true
            }
        case 'USER_NOT_LOADING':
            return {
                ...state,
                loading:false
            }
        case 'USER_REMOVE_SUCCESS':
            return {
                ...state,
                loading: false,
                deletionResult: true
            }
        case 'USER_REMOVE_RESET':
            return {
                ...state,
                deletionResult: null
            }
        default:
            return state
    }
}

