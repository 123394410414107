import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch } from 'react-redux'
import useAuth from '../../context/auth/useAuth'

export default function Dashboard() { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {client} = useAuth()

	useEffect(() => {
		if(client){

		}
	},[dispatch, t, client])

	return (
        <Container className='mt-5 px-5'>
            <Row>
                <Col>
                    <h1>{client?.username}, {t('admin.dashboard.welcome')}</h1>
                </Col>
            </Row>            
        </Container>
	)
}