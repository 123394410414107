import React, { Fragment, useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { changeFavourites, getUser } from '../../actions/usersActions'
import { getRestaurants } from '../../actions/restaurantsActions'
import { Restaurant } from '../../components/Restaurant'
import Loader from '../../components/loaders/Loader'

export default function Favourites() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {client} = useAuth()

    const {restaurants, loading: restaurantsLoading} = useSelector(state=>state.restaurants)
    const {loading: userLoading, user} = useSelector(state=>state.users)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getRestaurants())
    },[dispatch, t])

    useEffect(() => {
        if(client){
            dispatch(getUser(client?.id))
        }
    },[dispatch, client])

    const setFavourite = (restaurantid, newstate) => {
        dispatch(changeFavourites(restaurantid, newstate, user.id))
    }

    return (
        <Container className="px-3">
            <Row>
                <Col>
                    {userLoading || restaurantsLoading
                        ?
                           <Loader/>
                        :
                            user?.favourites?.length >0 
                            ?
                                restaurants?.map((restaurant, index) => (
                                    restaurant?.active
                                        ?
                                            user?.favourites?.includes(restaurant._id)
                                                ?
                                                <div key={index} className="my-3">
                                                    <Restaurant restaurant={restaurant} user={user} setFavourite={setFavourite} />
                                                </div>
                                                :
                                                <Fragment key={index}></Fragment>
                                        :
                                            <Fragment key={index}></Fragment>
                                ))     
                            :
                                <h3 className='text-center mt-5'>{t('client.restaurants.nofavourites')}</h3>   
                    }

                </Col>
            </Row>
        </Container>
    )
}