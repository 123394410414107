import AxiosControl from '../context/axios/AxiosControl'
const Axios = AxiosControl()

export const getRestaurants = () => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/restaurant/getall/', {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'RESTAURANT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_NOT_LOADING'
        })
    }
}

export const getNewopinions = () => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/restaurant/getnewopinions/', {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'RESTAURANT_GETNEWOPINIONS_SUCCESS',
                payload: data.newopinions
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_NOT_LOADING'
        })
    }
}

export const getRestaurant = (restaurant) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/restaurant/get/'+ restaurant, {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'RESTAURANT_GET_SUCCESS',
                payload: data.restaurant
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_NOT_LOADING'
        })
    }
}

export const getRestaurantsByRating = () => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status } = await Axios.get('/api/restaurant/getallbyrating/', {
            headers: { auth: localStorage.getItem('jwt') }
        })
        if (status === 200) {
            dispatch({
                type: 'RESTAURANT_GETALL_SUCCESS',
                payload: data
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_NOT_LOADING'
        })
    }
}

export const addRestaurant = (image, images, restaurantdata) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        if(image !== null && image !== undefined&& image !== '' && typeof image !== 'string'){
            let formData = new FormData()
            formData.append('image', image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: localStorage.getItem('jwt')
                }
            })
            restaurantdata.image = data
        }else{
            restaurantdata.image = image
        }

        for (const image of images) {
            let formData = new FormData()
            formData.append('image', image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: localStorage.getItem('jwt')
                }
            })
            restaurantdata.images.push(data)
        }

        const { data, status} = await Axios.post('/api/restaurant/register', restaurantdata, {
                headers: { auth: localStorage.getItem('jwt') }
            }
        )
        if (status === 200 || status === 264) {
            dispatch({
                type: 'RESTAURANT_ADD_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'RESTAURANT_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_NOT_LOADING'
        })
    }
}

export const editRestaurant = (image, images, restaurantdata, idRestaurant) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        if(image !== null && image !== undefined && image !== '' && typeof image !== 'string'){
            let formData = new FormData()
            formData.append('image', image)

            const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                headers: {
                    'Content-Type': `multipart/form-data`,
                    auth: localStorage.getItem('jwt')
                }
            })
            restaurantdata.image = data
        }else{
            restaurantdata.image = image
        }

        if(images !== restaurantdata.images){
            restaurantdata.images = []
            for (const image of images) {
                let formData = new FormData()
                formData.append('image', image)

                const {data} = await Axios.post(process.env.REACT_APP_DB_ADDRESS + '/api/upload/uploadimage', formData, {
                    headers: {
                        'Content-Type': `multipart/form-data`,
                        auth: localStorage.getItem('jwt')
                    }
                })
                restaurantdata.images.push(data)
            }
        }

        const { data, status} = await Axios.put('/api/restaurant/edit/' + idRestaurant, restaurantdata, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'RESTAURANT_EDIT_SUCCESS',
                payload: data.message
            })
            dispatch({
                type: 'RESTAURANT_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_ERROR'
        })
    }
}

export const enableRestaurant = (idRestaurant) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/restaurant/enable/' + idRestaurant, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'RESTAURANT_ENABLEDISABLE_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'RESTAURANT_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_ERROR'
        })
    }
}

export const disableRestaurant = (idRestaurant) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/restaurant/disable/' + idRestaurant, {}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 263 || status === 260) {
            dispatch({
                type: 'RESTAURANT_ENABLEDISABLE_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'RESTAURANT_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_ERROR'
        })
    }
}

export const setOpinion = (idUser, rating, comment, idRestaurant) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/restaurant/setopinion/' + idRestaurant, {commentary: {user: idUser, rating: rating, comment: comment}}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'RESTAURANT_SETOPINION_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'RESTAURANT_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_ERROR'
        })
    }
}

export const remmoveOpinion = (idOpinion, idRestaurant) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/restaurant/removeopinion/' + idRestaurant, {idopinion: idOpinion}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'RESTAURANT_SETOPINION_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'RESTAURANT_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_ERROR'
        })
    }
}

export const acceptOpinion = (idOpinion, idRestaurant) => async (dispatch) => {
    dispatch({
        type: 'RESTAURANT_LOADING'
    })
    try {
        const { data, status} = await Axios.put('/api/restaurant/acceptopinion/' + idRestaurant, {idopinion: idOpinion}, {
                headers: {
                    auth: localStorage.getItem('jwt')
                }
            }
        )
        if (status === 200 || status === 264 || status === 260) {
            dispatch({
                type: 'RESTAURANT_SETOPINION_SUCCESS',
                payload: data
            })
            dispatch({
                type: 'RESTAURANT_RESET_RESULT'
            })
        }
    } catch (error) {
        dispatch({
            type: 'RESTAURANT_ERROR'
        })
    }
}