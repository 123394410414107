import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../context/auth/useAuth'
import { getLogs } from '../../actions/logsActions'
import { CustomDataTable } from '../../components/design/Datatables/CustomDataTable'
import Loader from '../../components/loaders/Loader'

export default function Logs() { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {client} = useAuth()

	const {logs, loading} = useSelector(state=>state.logs)

	useEffect(() => {
		if(client){
			dispatch(getLogs())
		}
	},[dispatch, t, client])

    const columnsLogs = [
        {
            name: t('admin.logs.date'),
            cell: datos =>
                <div>
                    {datos.date.substr(8,2)+'/'+datos.date.substr(5,2)+'/'+datos.date.substr(0,4)+' - '+datos.date.substr(11,8)}
                </div>,
            selector: (datos) => datos._id,
            sortable: true,
        },{
            name: t('admin.logs.log'),
            cell: datos =>
                <div>
                    {datos.message}
                </div>,
            selector: (datos) => datos._id,
            sortable: true,
        }
    ]

	return (
        <Container className='mt-5 px-5'>
            <Row>
                <Col className='text-center'>
                    <h1>{t('admin.dashboard.logs')}</h1>
                </Col>
            </Row>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <Row className="fullwidth">
                            {logs?.length > 0
                                ?
                                    <CustomDataTable columns={columnsLogs} data={logs} exportable={false} printable={false}/>
                                :
                                    <h4 className='mt-4'>{t('admin.logs.thereisnotlogs')}</h4>
                            }
                        </Row>
            }
        </Container>
	)
}