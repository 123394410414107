import React, { useEffect } from 'react'
import { Container, Row, Col, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getRestaurants } from '../../../actions/restaurantsActions'
import Loader from '../../../components/loaders/Loader'

export default function Opinions() { 
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const {restaurants, opinions, loading} = useSelector(state=>state.restaurants)

    useEffect(() => {
        dispatch(getRestaurants())
    },[dispatch, t])

    return (
        <Container className='mt-5 px-5'>
            <Row>
                <Col className='text-center'>
                    <h1>{t('client.restaurants.opinions')}</h1>
                </Col>
            </Row>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <Row>
                                {restaurants?.map((restaurant, index) => (
                                    restaurant?.active
                                        ?
                                        <Col xs={6} lg={3} key={index} className='p-2'>
                                            <Link to={`/admin/opinions/${restaurant._id}`}>
                                                <Card  className="p-3">
                                                    <Card.Img variant="top" src={restaurant.image} style={{ maxHeight: '150px', width: 'auto' }} className='mx-auto'/>
                                                    <Card.Body>
                                                        <Card.Text className='text-center'>
                                                            <h3>{restaurant.name}</h3>
                                                            <h4>{t('typerestaurant.'+restaurant.category)}</h4>
                                                            
                                                            {opinions[restaurant._id] !== 0
                                                                ?
                                                                <div className='opiniones-pendientes'>
                                                                    <span className="fa-stack">
                                                                        <i className="fa fa-star-o fa-stack-2x"></i>
                                                                        <span>{opinions[restaurant._id]}</span>
                                                                    </span>
                                                                </div>
                                                                :
                                                                ''
                                                            }
                                                        </Card.Text>
                                                    </Card.Body>
                                                </Card>
                                            </Link>
                                        </Col>
                                        :
                                            ''
                                ))}
                        </Row>
            }
        </Container>
    )
}