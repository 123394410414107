import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addUser } from '../../actions/usersActions'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'
import ChangeLanguage from '../../translations/ChangeLanguage'

export default function RegisterClient() {     
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {user, resultuser, useractivate, loading} = useSelector(state=>state.users)

    const [formState, setFormState] = useState({})
    const [policy, setPolicy] = useState(false)
    const [isCorrect, setIsCorrect] = useState("")
    const [passwordverify, setPasswordverify] = useState("")
    
    const [showPasswordOne, setShowPasswordOne] = useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = useState(false)

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handlePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo)
    }

    const handlePolicy  = (e) => {
        setPolicy(e.target.checked)
    }

    const checkValidation = (isPassVer, e) => {
        const confirmPass = e.target.value

        if(isPassVer){
            setPasswordverify(confirmPass)
        }else{
            setFormState({
                ...formState,
                'password': confirmPass
            })
        }

        if (formState.password !== passwordverify) {
            setIsCorrect("Las contraseñas no coinciden")
        }else {
            setIsCorrect("")
        }
    }

    const handleAdd = async(e) => {
        e.preventDefault()
        if(isCorrect){
            if(formState.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,15}$/g)){
                dispatch(addUser(formState))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('users.passwordsnotvalid')
                })
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.passwordsnotmatch')
            })
        }
    }

    useEffect(() => {
        setFormState({
            username: '',
            email: '',
            password: '',
        })
    }, [user, dispatch])

    useEffect(() => {
        if(resultuser){
            switch (resultuser) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('users.gotoverify'),
                        icon: 'success',
                        text: t('users.useradd')
                    }).then((resultuser) => {
                        if (resultuser.isConfirmed) {
                            navigate('/activation/'+useractivate)
                        }
                    })
                    break;
                case 'email':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.emailduplicate')
                    })
                    break;
                case 'username':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.usernameduplicate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <>
            <div className='cabecera-auth d-flex justify-content-center align-items-center'>
                <img src="../images/logotipo-menu-login.png" alt="logotipo" className='logo'></img>
                <Link className="back px-3" to="/login">
                    <i className='fa fa-chevron-left fa-2x'></i>
                </Link>
                <ChangeLanguage />
            </div>            
            <Container className='mt-5 px-5'>
                <Row>
                    <Col className='text-center'>
                        <h1>{t('users.registration')}</h1>
                        <p>{t('users.frase')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="justify-content-center">
                        <Form className="px-4 formulario" onSubmit={handleAdd}>
                            <Row className="text-center my-3">
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='username' 
                                    value={formState.username ||  ''}
                                    placeholder={t('users.username')}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Row>
                            <Row className="text-center my-3">
                                <Form.Control
                                    className="sd-input"
                                    type='email'
                                    name='email' 
                                    value={formState.email || ''}
                                    placeholder={t('users.email')}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Row>
                            <Row className="text-center my-3 row-eye">
                                <Form.Control
                                    className="sd-input"
                                    type={showPasswordOne ? 'text' : 'password'}
                                    name='password' 
                                    value={formState.password ||  ''}
                                    placeholder={t('users.password')}
                                    onChange={(e) => checkValidation(false, e)}
                                    required
                                />
                                <img className="sd-imageneye" src={showPasswordOne ? "../images/eyeclose.svg" : "../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                            </Row>
                            <Row className="text-center my-3 row-eye">
                                <Form.Control
                                    className="sd-input"
                                    type={showPasswordTwo ? 'text' : 'password'}
                                    name='passwordcheck'
                                    placeholder={t('users.passwordconfirm')}
                                    onChange={(e) => checkValidation(true, e)}
                                    required
                                />
                                <img className="sd-imageneye" src={showPasswordTwo ? "../images/eyeclose.svg" : "../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityTwo}></img>
                            </Row>
                            <Row className="my-3">
                                <Col>
                                    {t('users.passwordneed1')}
                                    <ul>
                                        <li>{t('users.passwordneed2')}</li>
                                        <li>{t('users.passwordneed3')}</li>
                                        <li>{t('users.passwordneed4')}</li>
                                    </ul>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='d-flex align-items-start'>
                                    <Form.Check className='float-left pe-3' type='checkbox' name='policy' onChange={handlePolicy} checked={policy || false} />                                
                                    <p>
                                        {t('users.policy1')}
                                        <a href="https://redcordobasingluten.es/politica-de-privacidad/" target='_blank' rel="noreferrer"> <u>{t('users.policy2')}</u></a>
                                    </p>
                                </Col>
                            </Row>
                            <Row className="text-center my-3">
                                <Col>
                                    <Button variant='primary' type="submit" className="w-100 my-2" disabled={!policy}>
                                    {loading
                                        ?
                                            <>
                                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </>
                                        :
                                            <>{t('users.register')}</>
                                    }
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
