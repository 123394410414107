import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { Link, useParams } from 'react-router-dom'
import { editRestaurant, getRestaurant, enableRestaurant, disableRestaurant } from '../../../actions/restaurantsActions'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'

export default function RestaurantsEdit() { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {client} = useAuth()

    const { id } = useParams()

	const {restaurant, resultrestaurant, loading} = useSelector(state=>state.restaurants)

    const [dataRestaurants, setDataRestaurants] = useState()
    const [isEditing, setIsEditing] = useState(false)
    const [imageAux, setImage] = useState(null)
    const [imagesAux, setImages] = useState(null)

	useEffect(() => {
		if(client){
			dispatch(getRestaurant(id))
		}
	},[dispatch, t, client, id])

    useEffect(()=>{
        if (restaurant) {
            setDataRestaurants(restaurant)
			setImage(restaurant.image)
			setImages(restaurant.images)
        }
    }, [restaurant, client, dispatch])

    const handleInputChange = ({target})=>{
        setDataRestaurants({
            ...dataRestaurants,
            [target.name]: target.value
        })
    }

    const handleSelect = (target) => {
        setDataRestaurants({
            ...dataRestaurants,
            'category': target.target.value
        })
    }

    const handleImage = (e)=>{
		setImage(e.target.files[0])
    }

    const handleImages = (e) => {
        const files = Array.from(e.target.files)
        setImages(files)
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIsEditing(true)
        dispatch(editRestaurant(imageAux, imagesAux, dataRestaurants, id))
    }

    const disableEnableRestaurants = async(e) => {
        e.preventDefault()
        let textQuestion = ''
        dataRestaurants.active
            ?
                textQuestion = t('admin.restaurants.areyousuredisable')+'<br><p><label className="fs-20 fw-600 pt-5">'+dataRestaurants?.name+'</label></p>'
            :
                textQuestion = t('admin.restaurants.areyousureenable')+'<br><p><label className="fs-20 fw-600 pt-5">'+dataRestaurants?.name+'</label></p>'

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: textQuestion
        }).then((result) => {
            if (result.isConfirmed) {
                if (dataRestaurants.active) {
                    dispatch(disableRestaurant(dataRestaurants._id))
                }else{
                    dispatch(enableRestaurant(dataRestaurants._id))
                }
            }
        })
    }

    useEffect(() => {
        if(resultrestaurant){
            switch (resultrestaurant) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.restaurants.restaurantedit')
                    })
                    setIsEditing(false)
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultrestaurant])

	return (
        <Container className='mt-5 px-5'>
            <Row>
                <Col className='text-center'>
                    <h1>{t('admin.restaurants.editrestaurant')}</h1>
                </Col>
            </Row>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <Row className="my-5 d-flex justify-content-center">
                            <Col xs={12} lg={8}>
                                <Form className="px-4 formulario" onSubmit={handleEdit}>
                                    <Row className="text-center mt-3">
                                        <Col>
                                            <h2 className="sd-label text-start">{t('admin.restaurants.namerestaurant')}</h2>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='name' 
                                                value={dataRestaurants?.name ||  ''}
                                                placeholder={t('admin.restaurants.setname')}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.descriptionrestaurant')}*</h3>

                                            <Form.Control
                                                as='textarea'
                                                rows={5}
                                                className="sd-input"
                                                type='text'
                                                name='description'
                                                value={dataRestaurants?.description ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.descriptionrestaurant')} English</h3>
                                            <Form.Control
                                                as='textarea'
                                                rows={5}
                                                className="sd-input"
                                                type='text'
                                                name='descriptionen'
                                                value={dataRestaurants?.descriptionen ||  ''}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.category')}*</h3>
                                            <Form.Select
                                                className="form-control mt-2"
                                                onChange={handleSelect}
                                                id="category"
                                                name="category"
                                                value={dataRestaurants?.category}
                                            >
                                                <option value="Tradicionalcordobesa" defaultValue>{t('typerestaurant.Tradicionalcordobesa')}</option>
                                                <option value="Obradores">{t('typerestaurant.Obradores')}</option>
                                                <option value="Cafetería">{t('typerestaurant.Cafetería')}</option>
                                                <option value="Brunch">{t('typerestaurant.Brunch')}</option>
                                                <option value="Alimentosmedicinales">{t('typerestaurant.Alimentosmedicinales')}</option>
                                                <option value="Americana">{t('typerestaurant.Americana')}</option>
                                                <option value="Árabe">{t('typerestaurant.Árabe')}</option>
                                                <option value="Argentina">{t('typerestaurant.Argentina')}</option>
                                                <option value="Asador">{t('typerestaurant.Asador')}</option>
                                                <option value="Asiática">{t('typerestaurant.Asiática')}</option>
                                                <option value="Bar">{t('typerestaurant.Bar')}</option>
                                                <option value="Barbacoa">{t('typerestaurant.Barbacoa')}</option>
                                                <option value="Baresparacomer">{t('typerestaurant.Baresparacomer')}</option>
                                                <option value="Británica">{t('typerestaurant.Británica')}</option>
                                                <option value="Café">{t('typerestaurant.Café')}</option>
                                                <option value="Caribeña">{t('typerestaurant.Caribeña')}</option>
                                                <option value="Centroamericana">{t('typerestaurant.Centroamericana')}</option>
                                                <option value="Centroeuropea">{t('typerestaurant.Centroeuropea')}</option>
                                                <option value="Checa">{t('typerestaurant.Checa')}</option>
                                                <option value="China">{t('typerestaurant.China')}</option>
                                                <option value="Colombiana">{t('typerestaurant.Colombiana')}</option>
                                                <option value="Comedor">{t('typerestaurant.Comedor')}</option>
                                                <option value="Comidarápida">{t('typerestaurant.Comidarápida')}</option>
                                                <option value="Cubana">{t('typerestaurant.Cubana')}</option>
                                                <option value="DeCampania">{t('typerestaurant.DeCampania')}</option>
                                                <option value="DeEuropaoriental">{t('typerestaurant.DeEuropaoriental')}</option>
                                                <option value="DelcentrodeItalia">{t('typerestaurant.DelcentrodeItalia')}</option>
                                                <option value="DelsurdeItalia">{t('typerestaurant.DelsurdeItalia')}</option>
                                                <option value="DeOrienteMedio">{t('typerestaurant.DeOrienteMedio')}</option>
                                                <option value="Egipcia">{t('typerestaurant.Egipcia')}</option>
                                                <option value="Española">{t('typerestaurant.Española')}</option>
                                                <option value="Europea">{t('typerestaurant.Europea')}</option>
                                                <option value="Francesa">{t('typerestaurant.Francesa')}</option>
                                                <option value="Fusión">{t('typerestaurant.Fusión')}</option>
                                                <option value="Fusiónjaponesa">{t('typerestaurant.Fusiónjaponesa')}</option>
                                                <option value="Griega">{t('typerestaurant.Griega')}</option>
                                                <option value="Hawaiana">{t('typerestaurant.Hawaiana')}</option>
                                                <option value="India">{t('typerestaurant.India')}</option>
                                                <option value="Internacional">{t('typerestaurant.Internacional')}</option>
                                                <option value="Irlandesa">{t('typerestaurant.Irlandesa')}</option>
                                                <option value="Italiana">{t('typerestaurant.Italiana')}</option>
                                                <option value="Jamaicana">{t('typerestaurant.Jamaicana')}</option>
                                                <option value="Japonesa">{t('typerestaurant.Japonesa')}</option>
                                                <option value="Latina">{t('typerestaurant.Latina')}</option>
                                                <option value="Libanesa">{t('typerestaurant.Libanesa')}</option>
                                                <option value="Marisco">{t('typerestaurant.Marisco')}</option>
                                                <option value="Marroquí">{t('typerestaurant.Marroquí')}</option>
                                                <option value="Mediterránea">{t('typerestaurant.Mediterránea')}</option>
                                                <option value="Mexicana">{t('typerestaurant.Mexicana')}</option>
                                                <option value="Napolitana">{t('typerestaurant.Napolitana')}</option>
                                                <option value="Pakistaní">{t('typerestaurant.Pakistaní')}</option>
                                                <option value="Peruana">{t('typerestaurant.Peruana')}</option>
                                                <option value="Pizza">{t('typerestaurant.Pizza')}</option>
                                                <option value="Portuguesa">{t('typerestaurant.Portuguesa')}</option>
                                                <option value="Pub">{t('typerestaurant.Pub')}</option>
                                                <option value="Pubconcervezaartesanal">{t('typerestaurant.Pubconcervezaartesanal')}</option>
                                                <option value="Pubrestaurante">{t('typerestaurant.Pubrestaurante')}</option>
                                                <option value="Restaurantedecarne">{t('typerestaurant.Restaurantedecarne')}</option>
                                                <option value="Restaurantesdecerveza">{t('typerestaurant.Restaurantesdecerveza')}</option>
                                                <option value="Saludable">{t('typerestaurant.Saludable')}</option>
                                                <option value="Sichuan">{t('typerestaurant.Sichuan')}</option>
                                                <option value="Siciliana">{t('typerestaurant.Siciliana')}</option>
                                                <option value="Sopas">{t('typerestaurant.Sopas')}</option>
                                                <option value="Streetfood/Comidaenlacalle">{t('typerestaurant.Streetfood/Comidaenlacalle')}</option>
                                                <option value="Sudamericana">{t('typerestaurant.Sudamericana')}</option>
                                                <option value="Sushi">{t('typerestaurant.Sushi')}</option>
                                                <option value="Tailandesa">{t('typerestaurant.Tailandesa')}</option>
                                                <option value="Tiendagourmet">{t('typerestaurant.Tiendagourmet')}</option>
                                                <option value="Toscana">{t('typerestaurant.Toscana')}</option>
                                                <option value="Turca">{t('typerestaurant.Turca')}</option>
                                                <option value="Vasca">{t('typerestaurant.Vasca')}</option>
                                                <option value="Venezolana">{t('typerestaurant.Venezolana')}</option>
                                                <option value="Vinoteca">{t('typerestaurant.Vinoteca')}</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.positionxrestaurant')}*</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='positionx'
                                                value={dataRestaurants?.positionx ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.positionyrestaurant')}*</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='positiony'
                                                value={dataRestaurants?.positiony ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.addressrestaurant')}*</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='address'
                                                value={dataRestaurants?.address || ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.phonerestaurant')}*</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='phone'
                                                value={dataRestaurants?.phone || ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.webrestaurant')}</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='web'
                                                value={dataRestaurants?.web || ''}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.facebookrestaurant')}</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='facebook'
                                                value={dataRestaurants?.facebook ||  ''}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.xrestaurant')}</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='x'
                                                value={dataRestaurants?.x ||  ''}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.instagramrestaurant')}</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='instagram'
                                                value={dataRestaurants?.instagram ||  ''}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.setimage')}</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type='file'
                                                name='image'
                                                onChange={handleImage}
                                            />
                                            <div className='text-center my-3'>
                                            {imageAux
                                                ?
                                                    <img className="img-logo" alt="Preview" src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} />
                                                :
                                                    dataRestaurants?.image
                                                        ?
                                                            <img className="img-logo" alt="Preview" src={typeof dataRestaurants?.image === 'string' ? dataRestaurants?.image : URL.createObjectURL(dataRestaurants?.image)} />
                                                        :
                                                            <div className="img-logo"></div>
                                            }
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <h3>{t('admin.restaurants.setimages')}</h3>
                                            <Form.Control
                                                className="sd-input"
                                                type="file"
                                                multiple
                                                onChange={handleImages}
                                            />
                                            <div className='miniaturas'>
                                                {imagesAux?.map((imageAux, index) => (
                                                    <div key={index}>
                                                        <img className="img-miniatura" alt="Preview" src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} />
                                                    </div>
                                                ))}                                    
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="my-3">
                                            <Button variant='primary' type="submit" className="btn-primary w-100 my-2">
                                            {isEditing
                                                ?
                                                    <>
                                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </>
                                                :
                                                    <>{t('admin.restaurants.update')}</>
                                            }
                                            </Button>
                                            <Button type="button" className="btn-fourth w-100 my-3" onClick={(e) => disableEnableRestaurants(e)}>
                                                <label className='label-button'>{dataRestaurants?.active ? t('admin.restaurants.disable') : t('admin.restaurants.enable')}</label>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Link to="/admin/restaurants" className='btn btn-secondary'>Volver</Link>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
            }
        </Container>
	)
}