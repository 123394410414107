import axios from "axios"
import store from '../../store'

const axiosApiInstance = axios.create({
	baseURL: process.env.REACT_APP_DB_ADDRESS
})

const AxiosControl = () => {
	axiosApiInstance.interceptors.response.use(response => {
		return response
	 }, error => {
		if (error.response.status === 401 && error.response.data.message === "Invalid JWT Token") {
			store.dispatch({
				type: 'REDIRECT_INVALIDJWT'
			})
		}
		return error
	 })

	return axiosApiInstance
}

export default AxiosControl