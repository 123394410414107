import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Container, Button, Row, Form, Col} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { activeUser, sendVerificationCode } from '../../actions/usersActions'
import Swal from 'sweetalert2'
import { Link, useParams, useNavigate } from 'react-router-dom'
import ChangeLanguage from '../../translations/ChangeLanguage'
import Loader from '../../components/loaders/Loader'

export default function Activation() {     
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { idUser } = useParams()
    const {user, resultuser, loading} = useSelector(state=>state.users)

    const [active_code, setActivationcode] = useState(false)
    
    const ActiveCodeChange = ({target})=>{
        setActivationcode(target.value)
    }

    const activate = async(e) => {
        e.preventDefault()

        dispatch(activeUser(idUser, active_code))
    }

    const sendCode = async(e) => {
        e.preventDefault()

        dispatch(sendVerificationCode(idUser))
    }

    useEffect(() => {
    }, [user, dispatch])

    useEffect(() => {
        if(resultuser){
            switch (resultuser) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('users.gotologin'),
                        icon: 'success',
                        text: t('users.verified')
                    }).then((resultuser) => {
                        if (resultuser.isConfirmed) {
                            navigate('/')
                        }
                    })
                    break;
                case 'wrongcode':
                    Swal.fire({
                        showConfirmButton: true,
                        confirmButtonText: t('users.tryagain'),
                        icon: 'error',
                        text: t('users.wrongcode')
                    })
                    break;
                case 'emailsended':
                    Swal.fire({
                        showConfirmButton: true,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.emailsended')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    return (
        <>
            <div className='cabecera-auth d-flex justify-content-center align-items-center'>
                <img src="../images/logotipo-menu-login.png" alt="logotipo" className='logo'></img>
                <Link className="back px-3" to="/login">
                    <i className='fa fa-chevron-left fa-2x'></i>
                </Link>
                <ChangeLanguage />
            </div>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <Container className='mt-5 px-5'>
                            <Row className="my-3">
                                <Col className="d-flex justify-content-center">
                                {
                                    loading
                                        ?
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        :
                                            <Form className="p-4 formulario">
                                                <Row className="text-center my-3">
                                                    <Form.Control
                                                        className="login"
                                                        type='text'
                                                        name='active_code' 
                                                        value={active_code ||  ''}
                                                        placeholder={t('users.active_code')}
                                                        onChange={ActiveCodeChange}
                                                        required
                                                    />
                                                </Row>
                                                <Row className="text-center my-3">
                                                    <Button type="submit" variant="primary" className="w-100 my-2" onClick={activate}>{t('users.verify')}</Button>
                                                </Row>
                                                <Row className="text-center my-3">
                                                    <Button type="submit" variant="secondary" className="w-100 my-2" onClick={sendCode}>{t('users.sendverify')}</Button>
                                                </Row>
                                            </Form>
                                }
                                </Col>
                            </Row>
                        </Container>
            }
        </>
    )
}
