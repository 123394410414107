import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { Link, useParams } from 'react-router-dom'
import { editUser, getUser, enableUser, disableUser } from '../../../actions/usersActions'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'

export default function UsersProfile() { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {client} = useAuth()

    const { id } = useParams()

	const {user, resultuser, loading} = useSelector(state=>state.users)

    const [dataUser, setDataUser] = useState({'password': ''})
    const [isEditing, setIsEditing] = useState(false)
    const [isCorrect, setIsCorrect] = useState("")
    const [passwordverify, setPasswordverify] = useState("")
    
    const [showPasswordOne, setShowPasswordOne] = useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = useState(false)

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handlePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo)
    }

	useEffect(() => {
		if(client){
			dispatch(getUser(id))
		}
	},[dispatch, t, client, id])

    useEffect(()=>{
        if (user) {
            setDataUser({...user, 'password': ''})
        }
    }, [user, client, dispatch])

    const handleInputChange = ({target})=>{
        setDataUser({
            ...dataUser,
            [target.name]: target.value
        })
    }

    const checkValidation = (isPassVer, e) => {
        const confirmPass = e.target.value

        if(isPassVer){
            setPasswordverify(confirmPass)

            if (dataUser.password !== confirmPass) {
                setIsCorrect("Las contraseñas no coinciden")
            }else {
                setIsCorrect("")
            }
        }else{
            setDataUser({
                ...dataUser,
                'password': confirmPass
            })

            if (confirmPass !== passwordverify) {
                setIsCorrect("Las contraseñas no coinciden")
            }else {
                setIsCorrect("")
            }
        }
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        setIsEditing(true)
        if(isCorrect === '' || dataUser.password === ''){
            if(dataUser.password?.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,15}$/g) || dataUser.password === ''){
                dispatch(editUser(dataUser, id))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('users.passwordsnotvalid')
                })
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.passwordsnotmatch')
            })
        }
        setIsEditing(false)
    }

    const disableEnableUser = async(e) => {
        e.preventDefault()
        let textQuestion = ''
        dataUser.active
            ?
                textQuestion = t('users.areyousuredisable')+'<br><p><label className="fs-20 fw-600 pt-5">'+dataUser.username+'</label></p>'
            :
                textQuestion = t('users.areyousureenable')+'<br><p><label className="fs-20 fw-600 pt-5">'+dataUser.username+'</label></p>'

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: textQuestion
        }).then((result) => {
            if (result.isConfirmed) {
                if (dataUser.active) {
                    dispatch(disableUser(dataUser.id))
                }else{
                    dispatch(enableUser(dataUser.id))
                }
            }
        })
    }

    useEffect(() => {
        if(resultuser){
            switch (resultuser) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.useredit')
                    })
                    break;
                case 'email':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.emailduplicate')
                    })
                    break;
                case 'username':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.usernameduplicate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

	return (
        <>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <Container className='mt-5 px-5'>
                            <Row className="mb-3">
                                <Col xs={2} className='p-0'>
                                    <Link className="p-0 my-3" to="/admin/users">
                                        <img src="../../images/arrow-back.svg" alt="arrow-back" width={25}></img>
                                    </Link>
                                </Col>
                                <Col xs={8} className='text-center'>
                                    <h1 className='fs-18'>{t('admin.users.edituser')}</h1>
                                </Col>
                                <Col xs={2}></Col>
                            </Row>
                            <Row className="my-5 d-flex justify-content-center">
                                <Col xs={12} md={6}>
                                    <Form className="px-4 formulario" onSubmit={handleEdit}>
                                        <Row className="text-center mt-3">
                                            <h2 className="sd-label text-start">{t('admin.users.username')}</h2>
                                        </Row>
                                        <Row className="text-center mb-3 row-eye">
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='username' 
                                                value={dataUser.username ||  ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <img className="sd-imageneye" src="../../images/pencil.svg" alt="pencil" width={15}></img>
                                        </Row>
                                        <Row className="text-center mt-3">
                                            <h2 className="sd-label text-start">{t('admin.users.email')}</h2>
                                        </Row>
                                        <Row className="text-center mb-3 row-eye">
                                            <Form.Control
                                                className="sd-input"
                                                type='email'
                                                name='email' 
                                                value={dataUser.email || ''}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            <img className="sd-imageneye" src="../../images/pencil.svg" alt="pencil" width={15}></img>
                                        </Row>
                                        <Row className="text-center mt-3">
                                            <h2 className="sd-label text-start">{t('admin.users.password')}</h2>
                                        </Row>
                                        <Row className="text-center mb-3 row-eye">
                                            <Form.Control
                                                className="sd-input"
                                                type={showPasswordOne ? 'text' : 'password'}
                                                name='password' 
                                                value={dataUser.password ||  ''}
                                                onChange={(e) => checkValidation(false, e)}
                                            />
                                            <img className="sd-imageneye" src={showPasswordOne ? "../../images/eyeclose.svg" : "../../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                                        </Row>
                                        <Row className="my-3">
                                            <p>
                                                <span className='fs-12 fw-400 ps-1'>{t('users.passwordneed1')}</span><br/>
                                                <span className='password-list'>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed2')}</span>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed3')}</span>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed4')}</span>
                                                </span>
                                            </p>
                                        </Row>
                                        <Row className="text-center mt-3">
                                            <h2 className="sd-label text-start">{t('admin.users.passwordconfirm')}</h2>
                                        </Row>
                                        <Row className="text-center mb-3 row-eye">
                                            <Form.Control
                                                className="sd-input"
                                                type={showPasswordTwo ? 'text' : 'password'}
                                                name='passwordcheck'
                                                onChange={(e) => checkValidation(true, e)}
                                            />
                                            <img className="sd-imageneye" src={showPasswordTwo ? "../../images/eyeclose.svg" : "../../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityTwo}></img>
                                        </Row>
                                        <Row className="text-center my-3">
                                            <Col>
                                                <Button variant='primary' type="submit" className="btn-primary w-100 my-2">
                                                {isEditing
                                                    ?
                                                        <>
                                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                        </>
                                                    :
                                                        <>{t('users.update')}</>
                                                }
                                                </Button>
                                                <Button type="button" className="btn-fourth w-100 my-3" onClick={(e) => disableEnableUser(e)}>
                                                    <label className='label-button'>{dataUser.active ? t('users.disable') : t('users.enable')}</label>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Link to="/admin/users" className='btn btn-secondary'>Volver</Link>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
            }
        </>
	)
}