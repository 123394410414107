import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Button } from 'react-bootstrap';

export default function ChangeLanguage() {
    const { t } = useTranslation()
    const storedLanguage = localStorage.getItem('csg-lang')
    const defaultLanguage = (navigator.language || navigator.userAgent.userLanguage).split('-')[0]
    const [language, setLanguage] = useState(storedLanguage || defaultLanguage)

    useEffect(() => {
        if (storedLanguage) {
            i18n.changeLanguage(storedLanguage)
        }
    }, [storedLanguage])

    const changeLanguage = (button) => {
        const newLanguage = button.target.value
        localStorage.setItem('csg-lang', newLanguage)
        setLanguage(newLanguage)
        i18n.changeLanguage(newLanguage)
    };

    return (
        <div id="change-language" className='d-flex align-items-center'>
            <Button
                className={language === 'es' ? 'language_active' : 'language_inactive'}
                onClick={changeLanguage}
                value={'es'}
            >
                {t('global.langes')}
            </Button>
            <span className="text-white">|</span>
            <Button
                className={language === 'en' ? 'language_active' : 'language_inactive'}
                onClick={changeLanguage}
                value={'en'}
            >
                {t('global.langen')}
            </Button>
        </div>
    );
}
