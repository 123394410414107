import React, { useEffect } from 'react'
import { Container, Row, Col, Button, ProgressBar, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { acceptOpinion, getRestaurant, remmoveOpinion } from '../../../actions/restaurantsActions'
import { Link, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'

export default function Opinions() { 
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {client} = useAuth()

    const { id } = useParams()

    const {restaurant, loading} = useSelector(state=>state.restaurants)

	useEffect(() => {
		if(client){
			dispatch(getRestaurant(id))
		}
	},[dispatch, t, client, id])

    const deleteOpinion = async(e, opinion) => {
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: t('admin.restaurants.areyousureopiniondelete')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(remmoveOpinion(opinion, id))
            }
        })
    }

    const verifyOpinion = async(e, opinion) => {
        e.preventDefault()

        Swal.fire({
            showConfirmButton: true,
            showDenyButton: true,
            confirmButtonText: t('global.accept'),
            denyButtonText: t('global.cancel'),
            html: t('admin.restaurants.areyousureopinionverify')
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(acceptOpinion(opinion, id))
            }
        })
    }

    return (
        <Container className='mt-5 px-5'>   
            <Row className='mb-5'>
                <Col className='d-flex justify-content-center'>
                    <Link to="/admin/opinions" className='btn btn-secondary w-25'>Volver</Link>
                </Col>
            </Row>
            {
                loading
                    ?
                        <Loader/>
                    :
                    <>
                        {restaurant
                            ?
                                <>
                                    <Row>
                                        <Col className='text-center mb-3'>
                                            <h1>{restaurant.name}</h1>
                                        </Col>
                                    </Row>
                                    <Row className='g-4'>
                                    {restaurant?.opinions?.length > 0
                                        ?
                                            restaurant?.opinions?.map((opinion, index) => (
                                                opinion?.active
                                                    ?
                                                    <Col key={index} xs={6} lg={3}>
                                                        <Card >
                                                            <Card.Body>
                                                                {opinion?.user?.username}<br/>
                                                                <ProgressBar now={((opinion?.rating/5)*100)} title={opinion?.rating} className='my-2'/>
                                                                {opinion?.comment}<br/>
                                                                <div className='d-flex justify-content-around mt-3'>
                                                                    <Button variant='primary' type="submit" className="btn-primary my-2" onClick={(e) => deleteOpinion(e, opinion?._id)}>
                                                                        <i className="fa-solid fa-trash" title={t('admin.restaurants.removeopinion')}></i>
                                                                    </Button>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    :
                                                    <Col key={index} xs={6} lg={3}>
                                                        <Card>
                                                            <Card.Body>
                                                                {opinion?.user?.username}<br/>
                                                                <ProgressBar now={((opinion?.rating/5)*100)} title={opinion?.rating} className='my-2'/>
                                                                {opinion?.comment}<br/>
                                                                <div className='d-flex justify-content-around mt-3'>
                                                                    <Button variant='primary' type="submit" onClick={(e) => deleteOpinion(e, opinion?._id)}>
                                                                        <i className="fa-solid fa-trash" title={t('admin.restaurants.removeopinion')}></i>
                                                                    </Button>
                                                                    <Button variant='secondary' type="submit" className='btn btn-fourth' onClick={(e) => verifyOpinion(e, opinion?._id)}>
                                                                        <i className="fa-solid fa-circle-check" title={t('admin.restaurants.verifyopinion')}></i>                                                            
                                                                    </Button>
                                                                </div>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                            ))
                                        :
                                            ''
                                    }
                                    </Row>
                                </>
                            :
                                ''
                        }
                    </>
            }
        </Container>
    )
}