import { Card, ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

export const Restaurant = ({restaurant,user,setFavourite, loading}) => {
    const { t } = useTranslation()

    return (
        
        <Card className='grid-restaurante'>
            <Card.Body className='d-flex'>
                <Link to={`/client/restaurant/${restaurant?._id}`}>
                    <div className="imagen" style={{ backgroundImage: `url(${restaurant?.image})` }}>
                    </div>
                </Link>
                <div className='datos'>
                    <h4>{restaurant?.name}</h4>
                    <div className='gray mb-3'>{t('typerestaurant.'+restaurant?.category)}</div>
                    {restaurant?.averagerating >0
                                ?
                                    <div className='d-flex align-items-center'>
                                        <ProgressBar now={((restaurant?.averagerating/5)*100)} title={restaurant?.averagerating} className='me-2'/>
                                        {restaurant?.averagerating} / 5
                                    </div>                                    
                                :
                                    ''
                    }
                </div>
                <div className='botones-grid'>
                    {user
                        ?
                            user?.favourites?.includes(restaurant?._id)
                                ?
                                    <div className='favorito-selected cursor' onClick={() => setFavourite(restaurant?._id, false)}>
                                        {
                                            loading
                                            ?
                                                <span className="spinner-border spinner-border-sm align-middle"></span>
                                            :
                                                <i className='fa fa-heart' ></i>
                                        }
                                    </div>
                                :
                                    <div className='favorito cursor' onClick={() => setFavourite(restaurant?._id, true)}>
                                        {
                                            loading
                                            ?
                                                <span className="spinner-border spinner-border-sm align-middle"></span>
                                            :
                                                <i className='fa fa-heart-o'></i>
                                        }
                                    </div>
                        :
                            ''
                    }
                    <Link to={`/client/restaurant/${restaurant?._id}`} className='flecha'>
                        <i className='fa fa-chevron-right'></i>
                    </Link>
                </div>
            </Card.Body>
        </Card>
    )
}