import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { Link } from 'react-router-dom'
import { getUsers } from '../../../actions/usersActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Loader from '../../../components/loaders/Loader'

export default function Users() { 
  	const { t } = useTranslation()
	const dispatch = useDispatch()
	const {client} = useAuth()

	const {users, loading} = useSelector(state=>state.users)

    const [clients, setClients] = useState([])

	useEffect(() => {
		if(client){
			dispatch(getUsers())
		}
	},[dispatch, t, client])

    useEffect(()=>{
        if (users) {
            let userClient = users.filter(user => user.role === 'Client')
            setClients(userClient)
        }
    }, [users, client, dispatch])

	const columnsClients = [
		{
			name: t('admin.users.username'),
			cell: user =>
				<div>
					{user.username}
				</div>,
			selector: (user) => user.username,
			sortable: true,
		},{
			name: t('admin.users.email'),
			cell: user =>
				<div>
					{user.email}
				</div>,
			selector: (user) => user.email,
			sortable: true,
		},{
			name: t('admin.users.active'),
			cell: user =>
				<div>
                    {user.active
						?
                            <img src="../images/check.svg" alt="check" width={25}></img>
                        :
                            <img src="../images/times.svg" alt="times" width={25}></img>
					}
				</div>,
			selector: (user) => user.active,
			sortable: true,
		},{
			name: t('admin.users.verified'),
			cell: user =>
				<div>
                    {user.active_first
						?
                            <img src="../images/check.svg" alt="check" width={25}></img>
                        :
                            <img src="../images/times.svg" alt="times" width={25}></img>
					}
				</div>,
			selector: (user) => user.active_first,
			sortable: true,
		},{
			name: t('admin.users.profile'),
			cell: user =>
				<div>
					<Link to={`/admin/users/${user._id}`}>
						{t('admin.users.see')}
					</Link>
				</div>,
			sortable: false,
		}
	]

	return (
		<Container className='mt-5 px-5'>
            <Row>
                <Col className='text-center'>
                    <h1>{t('admin.users.users')}</h1>
                </Col>
            </Row>
			{
				loading
					?
						<Loader/>
					:
						<>
							<Row className="mt-5 ">
								<label className='sd-label-medium'>{t('admin.users.listclients')}</label>
							</Row>
							<Row className="mt-5 fullwidth">
								<Col>
								{clients?.length > 0
									?
										<CustomDataTable columns={columnsClients} data={clients} exportable={false} printable={false}/>
									:
										<h4 className='mt-4'>{t('admin.users.thereisnotclients')}</h4>
								}
								</Col>
							</Row>
						</>
			}
        </Container>
	)
}