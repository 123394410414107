import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en_translate from './languages/en.json'
import es_translate from './languages/es.json'

const userLang = navigator.language || navigator.userAgent.userLanguage
const defaultLocale = userLang.substring(0, 2)

let en_translate_user = ''
let es_translate_user = ''

// Se recorren todos los indices del elemento que se va a añadir en español
Object.keys(es_translate_user).forEach(element => {
	// Si existe tal elemento
	if(typeof es_translate[element] !== 'undefined'){
		// Se recorren todos los valores de cada elemento
		Object.keys(es_translate_user[element]).forEach(values => {
			// Si no existe tal valor, lo añade
			if(typeof es_translate[element][values] === 'undefined'){
				es_translate[element][values] = es_translate_user[element][values]
			}
		})
	}else{
	// Si no existe tal elemento, lo añade
		es_translate[element] = es_translate_user[element]
	}
})

// Se recorren todos los indices del elemento que se va a añadir en inglés
Object.keys(en_translate_user).forEach(element => {
	// Si existe tal elemento
	if(typeof en_translate[element] !== 'undefined'){
		// Se recorren todos los valores de cada elemento
		Object.keys(en_translate_user[element]).forEach(values => {
			// Si no existe tal valor, lo añade
			if(typeof en_translate[element][values] === 'undefined'){
				en_translate[element][values] = en_translate_user[element][values]
			}
		})
	}else{
		// Si no existe tal elemento, lo añade
		en_translate[element] = en_translate_user[element]
	}
})

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: en_translate
		},
		es: {
			translation: es_translate
		}
	},
	lng: defaultLocale,
	fallbackLng: defaultLocale,
	interpolation: {
		escapeValue: false
	}
})