import { Navigate } from "react-router-dom"

/**
 * Comprueba si el usuario está logeado
 * @returns Redirecciona al login si no está logeado y si lo está renderiza la vista pedida
 */
const PrivateAdminRouter = ({children}) => {
    const jwt = require("jsonwebtoken")
    const token = localStorage.getItem('jwt')
    let token_decode = null
    if (token!=='undefined'){
        token_decode = jwt.decode(token, { header: true })
        if(token_decode.rol === 'Admin'){
            return children
        }else{
            return <Navigate to="/"/>
        }
    }
}

export default PrivateAdminRouter