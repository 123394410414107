import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from 'react-redux'
import useAuth from '../../../context/auth/useAuth'
import { Link } from 'react-router-dom'
import { addRestaurant, getRestaurants } from '../../../actions/restaurantsActions'
import { CustomDataTable } from '../../../components/design/Datatables/CustomDataTable'
import Swal from 'sweetalert2'
import Loader from '../../../components/loaders/Loader'

export default function Restaurants() { 
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const {client} = useAuth()

    const {restaurants, resultrestaurant, loading} = useSelector(state=>state.restaurants)

    const [modalShow, setModalShow] = useState(false)
    const [newRestaurant, setNewRestaurant] = useState({})
    const [imageAux, setImage] = useState(null)
    const [imagesAux, setImages] = useState([])
    const [isRegistering, setIsRegistering] = useState(false)

    useEffect(() => {
        if(client){
            dispatch(getRestaurants())
        }
    },[dispatch, t, client])

    useEffect(()=>{
        if (restaurants) {

        }
    }, [restaurants, client, dispatch])

    const openModal = () =>{
        setNewRestaurant({
            name: '',
            description: '',
            descriptionen: '',
            category: 'Tradicionalcordobesa',
            positionx: '',
            positiony: '',
            address: '',
            phone: '',
            web: '',
            facebook: '',
            x: '',
            instagram: '',
            opinions: [],
            images: []
        })
        setModalShow(true)
    }

    const handleInputChange = ({target})=>{
        setNewRestaurant({
            ...newRestaurant,
            [target.name]: target.value
        })
    }

    const handleSelect = (target) => {
        setNewRestaurant({
            ...newRestaurant,
            'category': target.target.value
        })
    }

    const handleImage = (e)=>{
        setImage(e.target.files[0])
    }

    const handleImages = (e) => {
        const files = Array.from(e.target.files)
        setImages(files)
    }

    const handleAdd = async(e) => {
        e.preventDefault()
        setIsRegistering(true)
        dispatch(addRestaurant(imageAux, imagesAux, newRestaurant))
    }

    useEffect(() => {
        if(resultrestaurant){
            switch (resultrestaurant) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('admin.restaurants.addsuccess')
                    }).then(() => {
                        setModalShow(false)
                        setImage(null)
                        setImages([])
                        setIsRegistering(false)
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultrestaurant])

    const columnsRestaurants = [
        {
            name: t('admin.restaurants.name'),
            cell: restaurant =>
                <div>
                    {restaurant.name}
                </div>,
            selector: (restaurant) => restaurant.name,
            sortable: true,
        },{
            name: t('admin.restaurants.address'),
            cell: restaurant =>
                <div>
                    {restaurant.address}
                </div>,
            selector: (restaurant) => restaurant.address,
            sortable: true,
        },{            
            name: t('admin.restaurants.category'),
            cell: restaurant =>
                <div>
                    {t('typerestaurant.'+restaurant.category)}
                </div>,
            selector: (restaurant) => restaurant.category,
            sortable: true,
        },{
            name: t('admin.restaurants.active'),
            cell: restaurant =>
                <div>
                    {restaurant.active
                        ?
                            <img src="../images/check.svg" alt="check" width={25}></img>
                        :
                            <img src="../images/times.svg" alt="times" width={25}></img>
                    }
                </div>,
            selector: (restaurant) => restaurant.active,
            sortable: true,
        },{
            name: '',
            cell: restaurant =>
                <div>
                    <Link to={`/admin/restaurants/${restaurant._id}`}>
                        {t('admin.restaurants.edit')}
                    </Link>
                </div>,
            sortable: false,
        }
    ]

    return (
        <Container className='mt-5 px-5'>
            <Row>
                <Col className='text-center'>
                    <h1>{t('admin.restaurants.restaurants')}</h1>
                </Col>
            </Row>
            <Row className="mt-5 mb-4">
                <Button variant='primary' type="submit" className="btn-primary my-2 button-withicon" onClick={() => openModal()}>
                    <label className='label-button'>{t('admin.restaurants.createrestaurant')}</label>
                </Button>
            </Row>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <>
                            <Row className="mt-5 ">
                                <label className='sd-label-medium'>{t('admin.restaurants.listrestaurants')}</label>
                            </Row>
                            <Row className="fullwidth">
                                {restaurants?.length > 0
                                    ?
                                        <CustomDataTable columns={columnsRestaurants} data={restaurants} exportable={false} printable={false}/>
                                    :
                                        <h4 className='mt-4'>{t('admin.restaurants.thereisnotrestaurants')}</h4>
                                }
                            </Row>
                        </>
            }
            <Modal show={modalShow} className="sd-modal" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
                    <i className='fa fa-times' onClick={() => setModalShow(false)}></i>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center mb-5'>
                        <h2>{t('admin.restaurants.createrestaurant')}</h2>
                    </div>
                    <Form className="px-4 formulario" onSubmit={handleAdd}>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.namerestaurant')}*</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='name'
                                    value={newRestaurant.name ||  ''}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.descriptionrestaurant')}*</h3>
                                <Form.Control
                                    className="sd-input"
                                    as='textarea'
                                    rows={5}
                                    type='text'
                                    name='description'
                                    value={newRestaurant.description ||  ''}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.descriptionrestaurant')} English</h3>
                                <Form.Control
                                    className="sd-input"
                                    as='textarea'
                                    rows={5}
                                    type='text'
                                    name='descriptionen'
                                    value={newRestaurant.descriptionen ||  ''}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.category')}*</h3>
                                <Form.Select                                    
                                    onChange={handleSelect}
                                    id="category"
                                    name="category"
                                >
                                    <option value="Tradicionalcordobesa" defaultValue>{t('typerestaurant.Tradicionalcordobesa')}</option>
                                    <option value="Obradores">{t('typerestaurant.Obradores')}</option>
                                    <option value="Cafetería">{t('typerestaurant.Cafetería')}</option>
                                    <option value="Brunch">{t('typerestaurant.Brunch')}</option>
                                    <option value="Alimentosmedicinales">{t('typerestaurant.Alimentosmedicinales')}</option>
                                    <option value="Americana">{t('typerestaurant.Americana')}</option>
                                    <option value="Árabe">{t('typerestaurant.Árabe')}</option>
                                    <option value="Argentina">{t('typerestaurant.Argentina')}</option>
                                    <option value="Asador">{t('typerestaurant.Asador')}</option>
                                    <option value="Asiática">{t('typerestaurant.Asiática')}</option>
                                    <option value="Bar">{t('typerestaurant.Bar')}</option>
                                    <option value="Barbacoa">{t('typerestaurant.Barbacoa')}</option>
                                    <option value="Baresparacomer">{t('typerestaurant.Baresparacomer')}</option>
                                    <option value="Británica">{t('typerestaurant.Británica')}</option>
                                    <option value="Café">{t('typerestaurant.Café')}</option>
                                    <option value="Caribeña">{t('typerestaurant.Caribeña')}</option>
                                    <option value="Centroamericana">{t('typerestaurant.Centroamericana')}</option>
                                    <option value="Centroeuropea">{t('typerestaurant.Centroeuropea')}</option>
                                    <option value="Checa">{t('typerestaurant.Checa')}</option>
                                    <option value="China">{t('typerestaurant.China')}</option>
                                    <option value="Colombiana">{t('typerestaurant.Colombiana')}</option>
                                    <option value="Comedor">{t('typerestaurant.Comedor')}</option>
                                    <option value="Comidarápida">{t('typerestaurant.Comidarápida')}</option>
                                    <option value="Cubana">{t('typerestaurant.Cubana')}</option>
                                    <option value="DeCampania">{t('typerestaurant.DeCampania')}</option>
                                    <option value="DeEuropaoriental">{t('typerestaurant.DeEuropaoriental')}</option>
                                    <option value="DelcentrodeItalia">{t('typerestaurant.DelcentrodeItalia')}</option>
                                    <option value="DelsurdeItalia">{t('typerestaurant.DelsurdeItalia')}</option>
                                    <option value="DeOrienteMedio">{t('typerestaurant.DeOrienteMedio')}</option>
                                    <option value="Egipcia">{t('typerestaurant.Egipcia')}</option>
                                    <option value="Española">{t('typerestaurant.Española')}</option>
                                    <option value="Europea">{t('typerestaurant.Europea')}</option>
                                    <option value="Francesa">{t('typerestaurant.Francesa')}</option>
                                    <option value="Fusión">{t('typerestaurant.Fusión')}</option>
                                    <option value="Fusiónjaponesa">{t('typerestaurant.Fusiónjaponesa')}</option>
                                    <option value="Griega">{t('typerestaurant.Griega')}</option>
                                    <option value="Hawaiana">{t('typerestaurant.Hawaiana')}</option>
                                    <option value="India">{t('typerestaurant.India')}</option>
                                    <option value="Internacional">{t('typerestaurant.Internacional')}</option>
                                    <option value="Irlandesa">{t('typerestaurant.Irlandesa')}</option>
                                    <option value="Italiana">{t('typerestaurant.Italiana')}</option>
                                    <option value="Jamaicana">{t('typerestaurant.Jamaicana')}</option>
                                    <option value="Japonesa">{t('typerestaurant.Japonesa')}</option>
                                    <option value="Latina">{t('typerestaurant.Latina')}</option>
                                    <option value="Libanesa">{t('typerestaurant.Libanesa')}</option>
                                    <option value="Marisco">{t('typerestaurant.Marisco')}</option>
                                    <option value="Marroquí">{t('typerestaurant.Marroquí')}</option>
                                    <option value="Mediterránea">{t('typerestaurant.Mediterránea')}</option>
                                    <option value="Mexicana">{t('typerestaurant.Mexicana')}</option>
                                    <option value="Napolitana">{t('typerestaurant.Napolitana')}</option>
                                    <option value="Pakistaní">{t('typerestaurant.Pakistaní')}</option>
                                    <option value="Peruana">{t('typerestaurant.Peruana')}</option>
                                    <option value="Pizza">{t('typerestaurant.Pizza')}</option>
                                    <option value="Portuguesa">{t('typerestaurant.Portuguesa')}</option>
                                    <option value="Pub">{t('typerestaurant.Pub')}</option>
                                    <option value="Pubconcervezaartesanal">{t('typerestaurant.Pubconcervezaartesanal')}</option>
                                    <option value="Pubrestaurante">{t('typerestaurant.Pubrestaurante')}</option>
                                    <option value="Restaurantedecarne">{t('typerestaurant.Restaurantedecarne')}</option>
                                    <option value="Restaurantesdecerveza">{t('typerestaurant.Restaurantesdecerveza')}</option>
                                    <option value="Saludable">{t('typerestaurant.Saludable')}</option>
                                    <option value="Sichuan">{t('typerestaurant.Sichuan')}</option>
                                    <option value="Siciliana">{t('typerestaurant.Siciliana')}</option>
                                    <option value="Sopas">{t('typerestaurant.Sopas')}</option>
                                    <option value="Streetfood/Comidaenlacalle">{t('typerestaurant.Streetfood/Comidaenlacalle')}</option>
                                    <option value="Sudamericana">{t('typerestaurant.Sudamericana')}</option>
                                    <option value="Sushi">{t('typerestaurant.Sushi')}</option>
                                    <option value="Tailandesa">{t('typerestaurant.Tailandesa')}</option>
                                    <option value="Tiendagourmet">{t('typerestaurant.Tiendagourmet')}</option>
                                    <option value="Toscana">{t('typerestaurant.Toscana')}</option>
                                    <option value="Turca">{t('typerestaurant.Turca')}</option>
                                    <option value="Vasca">{t('typerestaurant.Vasca')}</option>
                                    <option value="Venezolana">{t('typerestaurant.Venezolana')}</option>
                                    <option value="Vinoteca">{t('typerestaurant.Vinoteca')}</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.positionxrestaurant')}*</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='positionx'
                                    value={newRestaurant.positionx ||  ''}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.positionyrestaurant')}*</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='positiony'
                                    value={newRestaurant.positiony ||  ''}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.addressrestaurant')}*</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='address'
                                    value={newRestaurant.address || ''}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.phonerestaurant')}*</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='phone'
                                    value={newRestaurant.phone || ''}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.webrestaurant')}</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='web'
                                    value={newRestaurant.web || ''}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.facebookrestaurant')}</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='facebook'
                                    value={newRestaurant.facebook ||  ''}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.xrestaurant')}</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='x'
                                    value={newRestaurant.x ||  ''}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.instagramrestaurant')}</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='text'
                                    name='instagram'
                                    value={newRestaurant.instagram ||  ''}
                                    onChange={handleInputChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.setimage')}</h3>
                                <Form.Control
                                    className="sd-input"
                                    type='file'
                                    name='image'
                                    onChange={handleImage}
                                />
                                <div className='text-center my-3'>
                                {imageAux
                                    ?
                                        <img className="img-logo" alt="Preview" src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} />
                                    :
                                        newRestaurant.image
                                            ?
                                                <img className="img-logo" alt="Preview" src={typeof newRestaurant.image === 'string' ? newRestaurant.image : URL.createObjectURL(newRestaurant.image)} />
                                            :
                                                <div className="img-logo"></div>
                                }
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <h3>{t('admin.restaurants.setimages')}</h3>
                                <Form.Control
                                    className="sd-input"
                                    type="file"
                                    multiple
                                    onChange={handleImages}
                                />
                                <div>
                                    <ul>
                                    {imagesAux.map((imageAux, index) => (
                                        <li key={index}>
                                            <img className="img-logo w-100" alt="Preview" src={typeof imageAux === 'string' ? imageAux : URL.createObjectURL(imageAux)} />
                                        </li>
                                    ))}
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="my-2">
                                <Button variant='primary' type="submit" className="w-100 my-2">
                                {isRegistering
                                    ?
                                        <>
                                            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </>
                                    :
                                        <>{t('admin.restaurants.register')}</>
                                }
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    )
}