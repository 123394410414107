import { useTranslation } from "react-i18next"
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import ChangeLanguage from "../../translations/ChangeLanguage"

export const EmailSend = () => {
    const { t } = useTranslation()
    const { email } = useParams()

    return (
        <>
            <div className='cabecera-auth d-flex justify-content-center align-items-center'>
                <img src="/images/logotipo-menu-login.png" alt="logotipo" className='logo'></img>  
                <Link className="back px-3" to="/login">
                    <i className='fa fa-chevron-left fa-2x'></i>
                </Link>
                <ChangeLanguage />
            </div>     
            <Container className='mt-5 px-5'>
                <Row>
                    <Col className='text-center'>                        
                        <h4>{t('forgot.emailsend1')}</h4>                        
                    </Col>
                </Row>                 
                <Row>        
                    <Col className='text-center'>
                        <h4><strong>{email}</strong></h4>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center'>
                        <h4>{t('forgot.emailsend2')}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-center'>
                        <h4><u><Link to="/forgotpassword" className="sd-forgot">{t('forgot.emailsend3')}</Link></u></h4>
                    </Col>
                </Row>
            </Container>
        </>
    )    
}
