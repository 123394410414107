import React, { useEffect, useState } from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { getRestaurants } from '../../actions/restaurantsActions'
import { getUser, changeFavourites } from '../../actions/usersActions'
import { Restaurant } from '../../components/Restaurant'
import FilterTypeRestaurant from '../../components/FilterTypeRestaurant'
import Loader from "../../components/loaders/Loader";

export default function Restaurants() {
    const { t } = useTranslation()
    const dispatch = useDispatch()
	const {client} = useAuth()

    const {restaurants, loading: restaurantsLoading} = useSelector(state=>state.restaurants)
    const {user, loading: usersLoading} = useSelector(state=>state.users)

    const [filterRestaurant, setFilterRestaurant] = useState('')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        dispatch(getRestaurants())
    },[dispatch, t])

    useEffect(() => {
        if(client){
            dispatch(getUser(client?.id))
        }
    },[dispatch, client])

    const setFavourite = (restaurantid, newstate) => {
        dispatch(changeFavourites(restaurantid, newstate, user.id))
    }

    const handleFilterChange = (category) => {
        setFilterRestaurant(category)
    }

    const uniqueCategories = [...new Set(restaurants?.map(restaurant => restaurant?.category))];

    const filteredRestaurants = filterRestaurant
        ? restaurants?.filter(restaurant => restaurant.category === filterRestaurant)
        : restaurants

    return (
        <>
            {
                restaurantsLoading
                    ?
                        <Loader/>
                    :
                        <Container className='px-3'>
                            <Row className='mt-4 mb-2'>
                                <Col xs={8} className='mx-auto text-center'>
                                    <FilterTypeRestaurant categories={uniqueCategories} onFilterChange={handleFilterChange}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {filteredRestaurants?.map((restaurant, index) => (
                                        restaurant?.active
                                            ?
                                                <div key={index} className="my-3">
                                                    <Restaurant restaurant={restaurant} user={user} setFavourite={setFavourite} loading={usersLoading} />
                                                </div>
                                            :
                                                ''
                                    ))}
                                </Col>
                            </Row>
                        </Container>
                }
            </>
    )
}