import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import useAuth from '../../context/auth/useAuth'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { editUser, getUser, removeUser } from '../../actions/usersActions'
import Loader from '../../components/loaders/Loader'

export default function Config() {
    const { t } = useTranslation()
	const {client,logout} = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {user, resultuser, deletionResult, loading} = useSelector(state=>state.users)

    const [passwordverify, setPasswordverify] = useState("")

    const [showPasswordOne, setShowPasswordOne] = useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = useState(false)

    const [formState, setFormState] = useState({})

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        })
        if(client){
            dispatch(getUser(client?.id))
        }
    },[dispatch, t, client])

    useEffect(() => {
        if(user){
            setFormState({
                username: user.username,
                email: user.email,
                password: ''
            })
        }
    // eslint-disable-next-line
    }, [user])

    const handleInputChange = ({target})=>{
        setFormState({
            ...formState,
            [target.name]: target.value
        })
    }

    const handlePasswordVisibilityOne = () => {
        setShowPasswordOne(!showPasswordOne)
    }

    const handlePasswordVisibilityTwo = () => {
        setShowPasswordTwo(!showPasswordTwo)
    }

    const checkValidation = (isPassVer, e) => {
        const confirmPass = e.target.value

        if(isPassVer){
            setPasswordverify(confirmPass)
        }else{
            setFormState({
                ...formState,
                'password': confirmPass
            })
        }
    }

    const handleEdit = async(e) => {
        e.preventDefault()
        if(formState.password === passwordverify || formState.password === ''){
            if((formState.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,8}$/g) && formState.password === passwordverify) || formState.password === ''){
                dispatch(editUser(formState, user.id))
            }else{
                Swal.fire({
                    showConfirmButton: true,
                    icon: 'warning',
                    text: t('users.passwordsnotvalid')
                })
            }
        }else{
            Swal.fire({
                showConfirmButton: true,
                icon: 'warning',
                text: t('users.passwordsnotmatch')
            })
        }
    }

    const handleDeleteAccountBtn = () => {
        Swal.fire({
            showCancelButton: true,
            icon: 'warning',
            text: t('users.deleteAccountConfirmationQuestion'),
            confirmButtonText: t('users.deleteAccountBtn'),
            confirmButtonColor: 'var(--rojo)',
            cancelButtonText: t('users.cancelDeleteAccountBtn')
        }).then(result => {
            if (result.isConfirmed) {
                dispatch(removeUser(client?.id))
            }
        })
    }

    useEffect(() => {
        if(resultuser){
            switch (resultuser) {
                case 'success':
                    Swal.fire({
                        showConfirmButton: true,
                        showDenyButton: false,
                        confirmButtonText: t('global.great'),
                        icon: 'success',
                        text: t('users.updated')
                    }).then((resultuser) => {
                        if (resultuser.isConfirmed) {
                            navigate('/client/restaurants')
                        }
                    })
                    break;
                case 'email':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.emailduplicate')
                    })
                    break;
                case 'username':
                    Swal.fire({
                        showConfirmButton: true,
                        icon: 'warning',
                        text: t('users.usernameduplicate')
                    })
                    break;
                default:
                    break;
            }
        }
    // eslint-disable-next-line
    }, [resultuser])

    useEffect(() => {
        if (deletionResult) {
            Swal.fire({
                showConfirmButton: true,
                icon: 'success',
                text: t('users.userDeleted')
            })
            dispatch({type: "USER_REMOVE_RESET"})
            logout()
        }
    // eslint-disable-next-line
    }, [deletionResult, dispatch])

    return (
        <>
            {
                loading
                    ?
                        <Loader/>
                    :
                        <Container>
                            <Row className="my-3">
                                <Col className='text-center'>
                                    <h1 className='m-3'>{t('users.updatedata')}</h1>
                                </Col>
                            </Row>
                            <Row className="mt-3 p-4 pb-0">
                                <Col className="justify-content-center">
                                    <Form className="px-4 formulario" onSubmit={handleEdit}>
                                        <Row className="text-center my-3">
                                            <h2 className="sd-label text-start">{t('users.username')}</h2>
                                            <Form.Control
                                                className="sd-input"
                                                type='text'
                                                name='username' 
                                                value={formState.username ||  ''}
                                                placeholder={t('users.username')}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Row>
                                        <Row className="text-center my-3">
                                            <h2 className="sd-label text-start">{t('users.email')}</h2>
                                            <Form.Control
                                                className="sd-input"
                                                type='email'
                                                name='email' 
                                                value={formState.email || ''}
                                                placeholder={t('users.email')}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Row>
                                        <Row className="text-center mt-3">
                                            <h2 className="sd-label text-start">{t('users.newpassword')}</h2>
                                        </Row>
                                        <Row className="text-center my-3 row-eye">
                                            <Form.Control
                                                className="sd-input"
                                                type={showPasswordOne ? 'text' : 'password'}
                                                name='password' 
                                                value={formState.password ||  ''}
                                                placeholder={t('users.password')}
                                                onChange={(e) => checkValidation(false, e)}
                                            />
                                            <img className="sd-imageneye" src={showPasswordOne ? "../images/eyeclose.svg" : "../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityOne}></img>
                                        </Row>
                                        <Row className="text-center mt-3">
                                            <h2 className="sd-label text-start">{t('users.newpasswordconfirm')}</h2>
                                        </Row>
                                        <Row className="text-center my-3 row-eye">
                                            <Form.Control
                                                className="sd-input"
                                                type={showPasswordTwo ? 'text' : 'password'}
                                                name='passwordcheck'
                                                placeholder={t('users.passwordconfirm')}
                                                onChange={(e) => checkValidation(true, e)}
                                            />
                                            <img className="sd-imageneye" src={showPasswordTwo ? "../images/eyeclose.svg" : "../images/eyeopen.svg"} alt="eyes" width={15} onClick={handlePasswordVisibilityTwo}></img>
                                        </Row>
                                        <Row className="my-3">
                                            <p>
                                                <span className='fs-12 fw-400 ps-1'>{t('users.passwordneed1')}</span><br/>
                                                <span className='password-list'>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed2')}</span>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed3')}</span>
                                                    <span className='fs-12 fw-400'><b>·</b> {t('users.passwordneed4')}</span>
                                                </span>
                                            </p>
                                        </Row>
                                        <Row className="text-center mt-3">
                                            <Col>
                                                <Button variant='primary' type="submit" className="btn-primary w-100 my-2">
                                                    <>{t('users.update')}</>
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <Link to="/" onClick={logout} className='btn btn-secondary'>
                                                    {t('signin.logout')} <i className='fas fa-sign-out'></i>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col className='d-grid'>
                                                <Button variant='warning' type='button' onClick={() => handleDeleteAccountBtn()}>
                                                    {t('signin.deleteAccount')}
                                                </Button>
                                            </Col>
                                        </Row>
                                        
                                    </Form>
                                </Col>
                            </Row>
                        </Container>
            }
        </>
    )
}