import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { usersReducer } from './reducers/usersReducer'
import { restaurantsReducer } from './reducers/restaurantsReducer'
import { logsReducer } from './reducers/logsReducer'

const intialState = {}
const appReducer = combineReducers({
    users: usersReducer,
    restaurants: restaurantsReducer,
    logs: logsReducer
})

const reducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

const composeEnhancer = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, intialState, composeEnhancer(applyMiddleware(thunk)))

export default store